<template>
  <div class="contact-us">
    <h1>Contact Us</h1>
    <form action="https://formspree.io/f/xnqykbqy" method="POST" class="contact-form">
      <div class="form-group">
        <label for="email">Your Email:</label>
        <input type="email" id="email" name="_replyto" required>
      </div>
      <div class="form-group">
        <label for="message">Your Message:</label>
        <textarea id="message" name="message" required></textarea>
      </div>
      <button type="submit" class="submit-button">Submit</button>
    </form>
  </div>
</template>

<style scoped>
.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px; /* This ensures that the form doesn't get too wide on larger screens */
  margin-top: 20px;
  box-sizing: border-box; /* Makes sure padding doesn't affect the overall width */
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%; /* Ensures they take the full width of the parent */
}

.submit-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
  width: 100%; /* Full width button is easier to tap on mobile */
}

.submit-button:hover {
  background-color: #2980b9;
}

/* Responsive styling for smaller devices */
@media only screen and (max-width: 480px) {
  .contact-form {
    padding: 0 10px; /* Slight padding on sides for small screens */
  }

  .submit-button {
    font-size: 16px;
  }
}
</style>