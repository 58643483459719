<template>
  <div :class="{ 'dark-mode': darkMode }">
    <router-view :dark-mode="darkMode" @toggle-dark="toggleDarkMode"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      darkMode: false,
    };
  },
  methods: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      document.body.classList.toggle('dark-mode', this.darkMode);  // Toggle dark-mode class on the body
    }
  }
}
</script>

/* App.vue - Global styles */
<style>
body.dark-mode {
  background-color: #121212;
  color: #FFFFFF;
}
/* Add other global styles here, e.g., for headers, footers, etc. */
</style>

