<template>
  <div class="row">

    <!-- Dark Mode Toggle Button at the top right corner -->
    <button class="dark-mode-toggle" @click="toggleDarkMode">
      {{ darkMode ? "Light Mode" : "Dark Mode" }}
    </button>

    <div class="col-12 col-md-8 offset-md-2 text-center py-5"> <!-- Added responsiveness classes -->
      <h1 class="display-4">Welcome to Listing Gen!</h1>
      <p class="lead mb-5">
        Our AI-powered tool can help you generate real estate listing descriptions quickly and easily!
      </p>

      <!-- Using input groups for a cohesive look -->
      <div class="input-group mb-3">
        <label for="addressInput" class="sr-only">Enter Address:</label> <!-- Accessibility -->
        <input id="addressInput" type="text" class="form-control" placeholder="Enter an address" v-model="address">
        <div class="input-group-append">
          <button class="btn btn-blue" @click="fetchDescription" :disabled="loadingDescription" v-tooltip="'Generate a property description'">
            Generate Description
            <div v-if="loadingDescription" class="loader"></div>
          </button>
        </div>
      </div>
      <button class="btn btn-light mb-3" @click="clearAll" v-tooltip="'Clear all fields and results'">
        <i class="fa fa-times"></i> Clear
      </button>

      <!-- Using a fade transition for the generated description card (subtle animation) -->
      <transition name="fade">
        <div v-if="generatedDescription" class="card mb-5">
          <div class="card-header">
            Generated Description:
          </div>
          <div class="card-body">
            <p>{{ generatedDescription }}</p>
            <p class="text-danger">{{ errorMessage }}</p>
            <h4 class="mb-3">Realtors, don't keep this secret to yourself!</h4>
            <p>Share Listing Gen with your colleagues and let them experience the magic of AI-powered listing descriptions. They'll save time, enhance their listings!</p>
                        
            <!-- Grouping share buttons -->
            <div class="dropdown">
                <button class="btn btn-blue dropdown-toggle" type="button" id="shareDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    Share
                </button>
                <div class="dropdown-menu" aria-labelledby="shareDropdown">
                    <a class="dropdown-item" @click="shareOnFacebook">Facebook</a>
                    <a class="dropdown-item" @click="shareOnTwitter">Twitter</a>
                    <a class="dropdown-item" @click="shareOnLinkedIn">LinkedIn</a>
                    <a class="dropdown-item" @click="shareViaEmail">Email</a>
                </div>
            </div>

            
            <!-- Changed "Copy URL" to "Copy Link" -->
            <button class="btn btn-blue mt-3" @click="copyToClipboard">
              <i class="fa fa-clipboard"></i> Copy Link
            </button>
          </div>
        </div>
      </transition>

      <!-- Using input groups for a cohesive look -->
      <div class="input-group mb-3">
        <label for="emailAddressInput" class="sr-only">Enter Address for Email Generation:</label> <!-- Accessibility -->
        <input id="emailAddressInput" type="text" class="form-control" placeholder="Enter an address for email generation" v-model="address">
        <div class="input-group-append">
          <button class="btn btn-blue" @click="fetchEmail" :disabled="loadingEmail">
            Generate Email
            <div v-if="loadingEmail" class="loader"></div>
          </button>
        </div>
      </div>

      <!-- Using a card layout for the generated email -->
      <transition name="fade">
        <div v-if="generatedEmail" class="card mb-5">
          <div class="card-header">
            Generated Email:
          </div>
          <div class="card-body">
            <p>{{ generatedEmail }}</p>
            <p class="text-danger">{{ errorMessage }}</p>
            
            <!-- Share and Copy Link buttons -->
            <div class="dropdown mt-3">
              <button class="btn btn-blue dropdown-toggle" type="button" id="emailShareDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Share
              </button>
              <div class="dropdown-menu" aria-labelledby="emailShareDropdown">
                <a class="dropdown-item" @click="shareOnFacebook">Facebook</a>
                <a class="dropdown-item" @click="shareOnTwitter">Twitter</a>
                <a class="dropdown-item" @click="shareOnLinkedIn">LinkedIn</a>
                <a class="dropdown-item" @click="shareViaEmail">Email</a>
              </div>
            </div>
            <button class="btn btn-blue mt-3" @click="copyToClipboard">
              <i class="fa fa-clipboard"></i> Copy Link
            </button>
          </div>
        </div>
      </transition>


      <p class="disclaimer mb-5">
        While Listing Gen strives to provide accurate and up-to-date information, 
        we cannot guarantee the validity of all facts and details presented. 
        Information can change over time and may become outdated or inaccurate. 
        Users are encouraged to review all information carefully.
      </p>

      <footer class="mt-auto"> <!-- Footer at the bottom -->
        <router-link to="/terms-of-service">Terms of Service</router-link> | 
        <router-link to="/privacy-policy">Privacy Policy</router-link>
      </footer>
    </div>
  </div>
  <div class="centered-horizontally">
    <stripe-buy-button
    buy-button-id="buy_btn_1NhG6bBKAqHX6mxR0mqR4yAe"
    publishable-key="pk_live_51N5JUdBKAqHX6mxRu1UIl8Kc7x8yCfgvsd9MNn1JbWui024xKaiate2UoGTnq3j0QqO3AW3rgsjnWzfElDDSC71600q3LZCUeQ">
    </stripe-buy-button>
    </div>
</template>



<script>
import axios from 'axios';

export default {
  props: {
    darkMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loadingDescription: false,
      loadingEmail: false,
      address: "",
      generatedDescription: null,
      generatedEmail: null,
      errorMessage: ""  // It seems you're using this but it's not declared. Added it here.
    };
  },
  methods: {
    async fetchDescription() {
      this.loadingDescription = true;
      const apiUrl = '/api/generate_description';
      try {
          const response = await axios.post(apiUrl, { address: this.address });
          this.generatedDescription = response.data.description;

          // Log the event to Firebase Analytics
          // firebaseAnalytics.logEvent('generate_description_event', { address: this.address });
      } catch (error) {
          console.error("Error during API call:", error);
          this.errorMessage = "There was an issue generating the description. Please try again.";
      } finally {
          this.loadingDescription = false;
      }
    },

    async fetchEmail() {
      this.loadingEmail = true;
      const apiUrl = '/api/generate_email';
      try {
          const response = await axios.post(apiUrl, { address: this.address });
          this.generatedEmail = response.data.email;

          // Log the event to Firebase Analytics
          // firebaseAnalytics.logEvent('generate_email_event', { address: this.address });
      } catch (error) {
          console.error("Error during API call:", error);
          this.errorMessage = "There was an issue generating the email. Please try again.";
      } finally {
          this.loadingEmail = false;
      }
    },

    shareOnFacebook() {
      const url = "https://listinggenforme.com";
      const text = `Check out Listing Gen - a powerful tool for realtors!: ${url}`;
      const encodedText = encodeURIComponent(text);
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodedText}`);
    },

    shareOnTwitter() {
      const url = "https://listinggenforme.com";
      const text = `Check out Listing Gen - a powerful tool for realtors!: ${url}`;
      const encodedText = encodeURIComponent(text);
      window.open(`https://twitter.com/intent/tweet?text=${encodedText}`);
    },

    shareOnLinkedIn() {
      const url = "https://listinggenforme.com";
      const text = `Check out Listing Gen - a powerful tool for realtors! : ${url}`;
      const encodedText = encodeURIComponent(text);
      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=Listing Gen Discount&summary=${encodedText}`);
    },

    shareViaEmail() {
      const url = "https://listinggenforme.com";
      const text = `Check out Listing Gen - a powerful tool for realtors!: ${url}`;
      const encodedText = encodeURIComponent(text);
      window.open(`mailto:?subject=Listing Gen Discount&body=${encodedText}`);
    },

    copyToClipboard() {
      const el = document.createElement('textarea');  // Create a new element
      el.value = "https://listinggenforme.com";  // Set its value to the string that you want copied
      document.body.appendChild(el);  // Add it to the DOM
      el.select();  // Select it
      document.execCommand('copy');  // Copy it
      document.body.removeChild(el);  // Remove it from the DOM
      alert('URL copied to clipboard!');  // Optional: Show an alert that the text has been copied
    },

    // New method to clear all fields and results
    clearAll() {
      this.address = "";
      this.generatedDescription = null;
      this.generatedEmail = null;
    },
    
    toggleDarkMode() {
      this.$emit('toggle-dark');  // Emitting the toggle event to the parent component
    }
  }
}
</script>

<style scoped>
/* Typography */
body, button, input {
  font-family: 'Roboto', sans-serif; /* Set the default font for the application */
}

/* Loader Styles */
.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Margin Adjustments */
.mb-2 {
  margin-bottom: 0.5rem!important;
}

.mb-3 {
  margin-bottom: 1rem!important;
}

.mb-5 {
  margin-bottom: 3rem!important;
}

/* Button Styles */
.btn-blue {
  background-color: #007bff;
  color: #fff; 
  border-color: #007bff; 
}

.btn:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
}

.btn:not(.btn-light):hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Card Styles */
.card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.card-header {
  background-color: #f5f5f5;
  padding: 10px;
  font-size: 1.25rem;
  border-bottom: 1px solid #e0e0e0;
}

.card-body {
  padding: 15px;
}

/* Icon Styles */
i.fa {
  margin-right: 5px;
}

/* Tooltip Styles */
[v-tooltip] {
  position: relative;
  cursor: pointer;
}

[v-tooltip]:hover::before {
  content: attr(v-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  pointer-events: none;
  margin-bottom: 5px;
}

/* Fade Transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: #121212;
  color: #FFFFFF;
}

body.dark-mode .card {
  background-color: #1E1E1E;
  color: #FFFFFF;
}

body.dark-mode .card-header {
  background-color: #2A2A2A;
}

body.dark-mode .btn-blue {
  background-color: #1A74DA;
  border-color: #1A74DA;
}

/* Dark Mode Toggle Button */
.dark-mode-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
  display: inline-block;
  background-color: #f5f5f5;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.dark-mode-toggle:hover {
  background-color: #e0e0e0;
}

.centered-horizontally {
  text-align: center; /* This will center inline-block or inline elements */
}

/* If the Stripe button isn't inline or inline-block, set it to be: */
stripe-buy-button {
  display: inline-block;
}

</style>
