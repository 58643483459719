<template>
  <default-layout>
    <h1>Terms of Service</h1>
    <div v-html="termsOfService"></div>
  </default-layout>
</template>

<script>
export default {
  data() {
    return {
      termsOfService: `
        <h2>Welcome to Listing Gen</h2>
        <p>These Terms of Service ("Terms") determine your use of the Listing Gen website and services ("Services"). By using our Services, you are agreeing to these Terms and our Privacy Policy. If you disagree with any part of the Terms, please refrain from using our Services.</p>

        <h2>Changes to the Terms</h2>
        <p>Creative Multiverse Studios holds the right to modify these Terms at any moment. We advise you to review the Terms periodically. If you continue to utilize our Services after the Terms have changed, this signifies your acceptance of the updated Terms.</p>

        <h2>Using Our Services</h2>
        <p>By using our Services, you agree to comply with all laws, rules, and regulations applicable. All intellectual property rights in our Services remain the property of Creative Multiverse Studios or its licensors.</p>

        <h2>Your Responsibilities</h2>
        <p>You are accountable for the security of your account on our platform. We recommend using strong, unique passwords and keeping them confidential.</p>

        <h2>Content and Conduct</h2>
        <p>Any content you access or post using our Services is your responsibility. We may not control all Content posted via the Services and cannot be held accountable for such Content. Ensure you comply with all applicable laws and regulations when using our Services.</p>

        <h2>Termination</h2>
        <p>Creative Multiverse Studios reserves the right to suspend or terminate your access to our Services at any time for any reason, especially if you breach these Terms.</p>

        <h2>Disclaimers and Limitations of Liability</h2>
        <p>Our Services are provided "AS IS". By using our Services, you agree that the risk associated with the Services rests entirely with you. To the extent permitted by law, Creative Multiverse Studios will not be liable for any indirect, incidental, or consequential damages related to your use of the Services.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns related to our Privacy Policy or Terms of Service, please visit our <a href="/contact">Contact Us</a> page.</p>


      `,
    };
  },
};
</script>

