import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VTooltip from 'v-tooltip';

// Importing the necessary styles for Bootstrap and Bootstrap-Vue
import 'bootstrap/dist/css/bootstrap.css';

// Create the app instance
const app = createApp(App);

// Use the plugins
app.use(router);
app.use(VTooltip);

// Mount the app
app.mount('#app');
