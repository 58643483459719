<template>
  <default-layout>
    <h1>Privacy Policy</h1>
    <div v-html="privacyPolicy"></div>
  </default-layout>
</template>

<script>
export default {
  data() {
    return {
      privacyPolicy: `
        <h2>Introduction</h2>
        <p>Welcome to Listing Gen by Creative Multiverse Studios. We prioritize the privacy of our users. This Privacy Policy explains how we manage your data and ensures that your information remains protected.</p>

        <h2>Information We Collect</h2>
        <ul>
          <li><strong>Usage Data:</strong> We gather data on how you interact with our services, including the frequency and duration of your activities.</li>
          <li><strong>Device and Connection Information:</strong> We collect details about your device, such as its model, operating system, unique identifiers, and network specifics.</li>
        </ul>

        <h2>How We Collect Information</h2>
        <ul>
          <li><strong>Directly from the User:</strong> When you use certain features on our platform.</li>
          <li><strong>Automatically:</strong> We gather some data automatically, like details about your device and which parts of our services you utilize.</li>
        </ul>

        <h2>Why We Collect Information</h2>
        <ul>
          <li>To upgrade our services.</li>
          <li>To comprehend how our services are used and to develop new tools and features.</li>
          <li>To communicate updates and promotional content about our service.</li>
        </ul>

        <h2>How We Safeguard Your Information</h2>
        <p>Our hosting providers in the US store the data we collect. We employ technical methods to keep your data safe. However, please note that no security measure is foolproof.</p>

        <h2>Sharing Your Information</h2>
        <p>We don't share your personal information without your permission, except:</p>
        <ul>
          <li><strong>Service Providers:</strong> We might share your data with third-party service providers to improve our service.</li>
          <li><strong>Legal Requirements:</strong> If necessary, we may disclose your information to comply with legal mandates.</li>
        </ul>

        <h2>Your Rights</h2>
        <p>You can review, modify, or delete your account at any time. If you believe any of your information with us is incorrect or incomplete, please let us know.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns related to our Privacy Policy or Terms of Service, please visit our <a href="/contact">Contact Us</a> page.</p>

        <h2>Updates to the Privacy Policy</h2>
        <p>We might occasionally update this policy. If there are significant changes, we will inform you.</p>
      `,
    };
  },
};
</script>

